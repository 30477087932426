import { AppError, catchException, createError } from "@model/utils/error";
import AuthService from "@services/firebase/auth.service";
import { User } from "firebase/auth";
import { autorun, makeAutoObservable, when } from "mobx";
import { inject } from "react-ioc";

export const MIGRATION_REDIRECT_URL =
    process.env.REACT_APP_ASPEN_MIGRATION_REDIRECT_URL;

const IS_DEV_MODE =
    Boolean(process.env.REACT_APP_DEBUG_MODE) ||
    process.env.REACT_APP_ENVIRONMENT === "development";

export default class AuthStore {
    authService = inject(this, AuthService);

    authUser: User | null = null;

    isAuthChecked = false;

    isLoading = false;

    error: AppError | null = null;

    aspenAccess = false;

    // constructor

    constructor() {
        makeAutoObservable(this);

        autorun(() => {
            this.authService.auth.onAuthStateChanged(async (user) => {
                await this.setAuthUser(user);
                if (IS_DEV_MODE) {
                    console.log("AUTH USER", user);
                }
            }); // TODO
        });
    }

    // computed

    get userId(): string | undefined {
        return this.authUser?.uid;
    }

    get isAuthorized(): boolean {
        return !!this.userId;
    }

    get hasAspenAccess(): boolean {
        return this.aspenAccess;
    }

    // actions

    protected async setAuthUser(user: User | null) {
        this.authUser = user;
        this.isAuthChecked = true;
        if (!user) {
            document.cookie = "arbor_tkn=expired;max-age=0";
            return;
        }

        const idTokenResult = await user.getIdTokenResult(true);
        const { allowAspenAccess } = idTokenResult.claims;
        this.aspenAccess = allowAspenAccess;

        if (allowAspenAccess) {
            // Explicit logging for redirect for datadog
            console.log(`ASPEN MIGRATION REDIRECT: userID ${this.userId}`);

            await this.logout();
            const redirectUrl = MIGRATION_REDIRECT_URL;

            window.location.href = redirectUrl || "https://www.joinarbor.com"; // redirect to Willow, fallback to marketing website
        }

        document.cookie = `arbor_tkn=${idTokenResult.token};expires=${
            idTokenResult.expirationTime
        };${!window.location.host.includes("localhost") ? "secure;" : ""}`;
    }

    async loginWithLink(email: string, link: string) {
        try {
            this.isLoading = true;
            this.error = null;
            const { user } = await this.authService.loginWithEmailLink(
                email,
                link,
            );
            this.setAuthUser(user);
        } catch (error) {
            catchException(error);
            this.error = createError(
                "UNKNOWN",
                "Oops!",
                "Unexpected error while login",
            );
        } finally {
            this.isLoading = false;
        }
        return this.error;
    }

    async logout(): Promise<AppError | void> {
        try {
            this.isLoading = true;
            sessionStorage.removeItem("sessionId");
            await this.authService.logout();
            await when(() => !this.isAuthorized);
        } catch (error) {
            catchException(error);
            this.error = createError();
            return this.error;
        } finally {
            this.isLoading = false;
        }
    }
}
