import * as React from "react";
import { observer } from "mobx-react-lite";
import { Navigate, RouteProps, Outlet, useLocation } from "react-router-dom";
import { useInstance } from "react-ioc";
import AuthStore, { MIGRATION_REDIRECT_URL } from "@store/auth/auth.store";

const PrivateOutlet: React.FC<RouteProps> = observer(() => {
    const location = useLocation();
    const auth = useInstance(AuthStore);

    if (auth.hasAspenAccess) {
        // Explicit logging for redirect for datadog
        console.log(`ASPEN MIGRATION REDIRECT: userID ${auth.userId}`);

        // For external URLs, use window.location
        window.location.href =
            MIGRATION_REDIRECT_URL || "https://www.joinarbor.com"; // redirect to Willow, fallback to marketing website
        return null;
    }

    if (!auth.isAuthorized) {
        return (
            <Navigate
                to="/login"
                replace
                state={{ afterLogin: location.pathname, replace: true }}
            />
        );
    }

    return <Outlet />;
});

export default PrivateOutlet;
